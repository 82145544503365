import * as React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { TERMS } from "../../../constants"
import { Title } from "../../ui"
import "./PrivacyPolicy.scss"

const PrivacyPolicy = () => {
  return (
    <div className="privacy__section">
      <Container>
        <Row>
          <Col>
            <Title header={TERMS.PRIVACY_POLICY.title} color="BlackRussian" />
            <p
              dangerouslySetInnerHTML={{ __html: TERMS.PRIVACY_POLICY.message }}
            />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default PrivacyPolicy
